import qs from 'qs';
import axiosInstance, { mode, sessionId } from './axios';

// --------Companies----------
export const companiesApi = {
  companyList() {
    return axiosInstance
      .post(
        `company/managed?fields=website,legalEntityId${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyGet(companyId) {
    return axiosInstance
      .get(
        `company/get?companyIds=${companyId}&fields=website,legalEntityId,bankAccountIds${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyCreate(name, website) {
    return axiosInstance
      .post(
        `company/create?name=${name}&website=${website}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyUpdate(companyId, name, website) {
    return axiosInstance
      .post(
        `company/update?name=${name}&website=${website}&companyId=${companyId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyDelete(companyId) {
    return axiosInstance
      .post(
        `company/delete?companyId=${companyId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //---Legal Entity
  legalEntityGet(legalEntityId) {
    return axiosInstance
      .get(
        `legalEntity/get?legalEntityId=${legalEntityId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityCreate(name, countryId, companyId, street, city, zip) {
    return axiosInstance
      .post(
        `legalEntity/create?name=${name}&countryId=${countryId}&companyId=${companyId}&street=${street}&city=${city}&zip=${zip}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityUpdate(legalEntityId, name, countryId, street, city, zip) {
    return axiosInstance
      .post(
        `legalEntity/update?name=${name}&countryId=${countryId}&legalEntityId=${legalEntityId}&street=${street}&city=${city}&zip=${zip}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityDelete(legalEntityId) {
    return axiosInstance
      .post(
        `legalEntity/delete?legalEntityId=${legalEntityId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //---Bank Account
  bankAccountGet(bankAccountIds) {
    return axiosInstance
      .get(
        `bank/account/get?bankAccountIds=${bankAccountIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountCreate(bankId, currencyId, companyId, iban) {
    return axiosInstance
      .post(
        `bank/account/create?bankId=${bankId}&currencyId=${currencyId}&companyId=${companyId}&iban=${iban}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountUpdate(bankAccountId, bankId, currencyId, companyId, iban) {
    return axiosInstance
      .post(
        `bank/account/update?bankAccountId=${bankAccountId}&bankId=${bankId}&currencyId=${currencyId}&companyId=${companyId}&iban=${iban}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountDelete(bankAccountId) {
    return axiosInstance
      .post(
        `bank/account/delete?bankAccountId=${bankAccountId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //--------Invoices----------
  invoiceList(companyId) {
    return axiosInstance
      .get(
        `invoice/list?companyId=${companyId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceGet(invoiceIds) {
    return axiosInstance
      .get(
        `invoice/get?invoiceIds=${invoiceIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceCreate(fromId, toId, bankAccountId, note, number, date) {
    return axiosInstance
      .post(
        `invoice/create?fromId=${fromId}&toId=${toId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceUpdate(fromId, toId, bankAccountId, invoiceId, note, number, date) {
    return axiosInstance
      .post(
        `invoice/update?fromId=${fromId}&toId=${toId}&bankAccountId=${bankAccountId}&invoiceId=${invoiceId}&note=${note}&number=${number}&date=${date}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceDelete(invoiceId) {
    return axiosInstance
      .post(
        `invoice/delete?invoiceId=${invoiceId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryGet(invoiceEntryIds) {
    return axiosInstance
      .get(
        `invoice/entry/get?invoiceEntryIds=${invoiceEntryIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryCreate(
    invoiceId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/create?invoiceId=${invoiceId}&description=${description}&encRate=${encRate}&encAmount=${encAmount}&encPrice=${encPrice}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryCreateEncrypt(
    invoiceId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/create?invoiceId=${invoiceId}&description=${description}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`,
        qs.stringify({
          encRate: encRate,
          encAmount: encAmount,
          encPrice: encPrice,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryUpdate(
    invoiceEntryId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/update?invoiceEntryId=${invoiceEntryId}&description=${description}&encRate=${encRate}&encAmount=${encAmount}&encPrice=${encPrice}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryUpdateEncrypt(
    invoiceEntryId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/update?invoiceEntryId=${invoiceEntryId}&description=${description}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`,
        qs.stringify({
          encRate: encRate,
          encAmount: encAmount,
          encPrice: encPrice,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryDelete(invoiceEntryId) {
    return axiosInstance
      .post(
        `invoice/entry/delete?invoiceEntryId=${invoiceEntryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
