import { companiesApi } from '../../api/companiesApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  companiesList: null,
  companyInfo: null,
  legalEntityInfo: null,
  legalEntityInfoArray: [],
  bankAccounts: [],
  invoiceList: null,
  openInvoice: null,
  invoiceEntry: [],
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setCompaniesList(state, action) {
      state.companiesList = action.payload;
    },
    setCompanyInfo(state, action) {
      state.companyInfo = action.payload;
    },

    addInCompanyList(state, action) {
      state.companiesList = [...state.companiesList, action.payload];
      state.companyInfo = action.payload;
    },
    updateCompany(state, action) {
      const newCompany = action.payload;
      state.companiesList = state.companiesList.map((item) => {
        if (item.id === newCompany.id)
          return {
            ...item,
            name: newCompany.name,
            website: newCompany.website,
          };
        return item;
      });
      state.companyInfo = {
        ...state.companyInfo,
        name: action.payload.name,
        website: action.payload.website,
      };
    },
    deleteCompany(state, action) {
      const id = action.payload;
      state.companiesList = state.companiesList.filter(
        (item) => item.id !== id
      );
      state.companyInfo = null;
    },
    ///---Legal Entity---
    setLegalEntityInfo(state, action) {
      state.legalEntityInfo = action.payload;
    },
    resetLegalEntityInfo(state, action) {
      state.legalEntityInfo = null;
    },
    updateLegalEntityInfo(state, action) {
      state.legalEntityInfo = action.payload;
      state.companyInfo = {
        ...state.companyInfo,
        legalEntityId: action.payload.id,
      };
    },
    //---Bank Account---
    setBankAccounts(state, action) {
      state.bankAccounts = action.payload;
    },
    addInBankAccounts(state, action) {
      state.bankAccounts = [...state.bankAccounts, action.payload];
      state.companyInfo = {
        ...state.companyInfo,
        bankAccountIds: [
          ...state.companyInfo.bankAccountIds,
          action.payload.id,
        ],
      };
    },
    updateBankAccounts(state, action) {
      const newBankAccouns = action.payload;
      state.bankAccounts = state.bankAccounts.map((item) => {
        if (item.id === newBankAccouns.id) return newBankAccouns;
        return item;
      });
    },
    deleteBankAccount(state, action) {
      const id = action.payload;
      state.bankAccounts = state.bankAccounts.filter((item) => item.id !== id);
    },
    //---Invoice---
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setOpenInvoice(state, action) {
      state.openInvoice = action.payload;
    },
    addInInvoiceList(state, action) {
      state.invoiceList = [...state.invoiceList, action.payload];
    },
    setLegalEntityInfoArray(state, action) {
      state.legalEntityInfoArray = [
        ...state.legalEntityInfoArray,
        action.payload,
      ];
    },
    updateInvoice(state, action) {
      const newInvoice = action.payload;
      state.invoiceList = state.invoiceList.map((item) => {
        if (item.id === newInvoice.id) return newInvoice;
        return item;
      });
      const { fromId, toId, bankAccountId, note, number, date } =
        action.payload;
      state.openInvoice = {
        ...state.openInvoice,
        fromId,
        toId,
        bankAccountId,
        note,
        number,
        date,
      };
    },
    deleteInvoice(state, action) {
      const id = action.payload;
      state.invoiceList = state.invoiceList.filter((item) => item.id !== id);
      state.openInvoice = null;
    },
    setInvoiceEntry(state, action) {
      state.invoiceEntry = action.payload;
    },
    addInInvoiceEntry(state, action) {
      state.invoiceEntry = [...state.invoiceEntry, action.payload];
    },
    updateInvoiceEntry(state, action) {
      const newInvoiceEntry = action.payload;
      state.invoiceEntry = state.invoiceEntry.map((item) => {
        if (item.id === newInvoiceEntry.id) return newInvoiceEntry;
        return item;
      });
    },
    updateInvoiceEntryDragNDrop(state, action) {
      // console.log(action.payload)
      state.invoiceEntry = action.payload;
    },
    deleteInvoiceEntry(state, action) {
      const id = action.payload;
      state.invoiceEntry = state.invoiceEntry.filter((item) => item.id !== id);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCompanyInfo,
  resetLegalEntityInfo,
  setBankAccounts,
  setOpenInvoice,
  setInvoiceList,
  updateInvoiceEntryDragNDrop,
} = slice.actions;

// ---------------Companies---------------------------------

export function getCompaniesList() {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setCompaniesList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyGet(companyId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyGet(companyId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items[0];
        dispatch(slice.actions.setCompanyInfo(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyCreate(name, website) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyCreate(name, website);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(
          slice.actions.addInCompanyList({
            id,
            name,
            website,
            bankAccountIds: [],
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyDelete(companyId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyDelete(companyId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteCompany(companyId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyUpdate(companyId, name, website) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyUpdate(
        companyId,
        name,
        website
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.updateCompany({ id, name, website }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Legal Entity---------------------------------

export function legalEntityGet(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityGet(legalEntityId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.item;
        dispatch(slice.actions.setLegalEntityInfo(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function legalEntityCreate(
  name,
  countryId,
  companyId,
  street,
  city,
  zip
) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityCreate(
        name,
        countryId,
        companyId,
        street,
        city,
        zip
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateLegalEntityInfo({
            id,
            name,
            countryId,
            street,
            city,
            zip,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function legalEntityUpdate(
  legalEntityId,
  name,
  countryId,
  street,
  city,
  zip
) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityUpdate(
        legalEntityId,
        name,
        countryId,
        street,
        city,
        zip
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateLegalEntityInfo({
            id,
            name,
            countryId,
            street,
            city,
            zip,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function legalEntityDelete(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityDelete(legalEntityId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.setLegalEntityInfo(null));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Bank Account---------------------------------

export function bankAccountGet(bankAccountIds) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountGet(bankAccountIds);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setBankAccounts(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankAccountCreate(bankId, currencyId, companyId, iban) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountCreate(
        bankId,
        currencyId,
        companyId,
        iban
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.addInBankAccounts({
            id,
            bankId,
            currencyId,
            companyId,
            iban,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankAccountUpdate(
  bankAccountId,
  bankId,
  currencyId,
  companyId,
  iban
) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountUpdate(
        bankAccountId,
        bankId,
        currencyId,
        companyId,
        iban
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateBankAccounts({
            id,
            bankId,
            currencyId,
            companyId,
            iban,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankAccountDelete(bankAccountId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountDelete(bankAccountId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteBankAccount(bankAccountId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Invoice---------------------------------

export function getInvoiceList(companyId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceList(companyId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setInvoiceList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceGet(invoiceIds) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceGet(invoiceIds);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items[0];
        dispatch(slice.actions.setOpenInvoice(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceCreate(fromId, toId, bankAccountId, note, number, date) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceCreate(
        fromId,
        toId,
        bankAccountId,
        note,
        number,
        date
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.addInInvoiceList({
            id,
            fromId,
            toId,
            bankAccountId,
            note,
            number,
            date,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceUpdate(
  fromId,
  toId,
  bankAccountId,
  invoiceId,
  note,
  number,
  date
) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceUpdate(
        fromId,
        toId,
        bankAccountId,
        invoiceId,
        note,
        number,
        date
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateInvoice({
            id,
            fromId,
            toId,
            bankAccountId,
            note,
            number,
            date,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceDelete(invoiceId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceDelete(invoiceId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteInvoice(invoiceId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function legalEntityGetArray(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityGet(legalEntityId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.item;
        dispatch(slice.actions.setLegalEntityInfoArray(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceEntryGet(invoiceEntryIds) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceEntryGet(invoiceEntryIds);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setInvoiceEntry(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceEntryCreate(
  invoiceId,
  description,
  encRate,
  encAmount,
  encPrice,
  orderIndex
) {
  return async (dispatch) => {
    try {
      // const response = await companiesApi.invoiceEntryCreate(invoiceId, description, encRate, encAmount, encPrice, orderIndex);
      const response = await companiesApi.invoiceEntryCreateEncrypt(
        invoiceId,
        description,
        encRate,
        encAmount,
        encPrice,
        orderIndex
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.addInInvoiceEntry({
            id,
            invoiceId,
            description,
            encRate,
            encAmount,
            encPrice,
            orderIndex,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function invoiceEntryUpdate(
  invoiceEntryId,
  invoiceId,
  description,
  encRate,
  encAmount,
  encPrice,
  orderIndex
) {
  return async (dispatch) => {
    try {
      // const response = await companiesApi.invoiceEntryUpdate(invoiceEntryId, description, encRate, encAmount, encPrice, orderIndex);
      const response = await companiesApi.invoiceEntryUpdateEncrypt(
        invoiceEntryId,
        description,
        encRate,
        encAmount,
        encPrice,
        orderIndex
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateInvoiceEntry({
            id,
            invoiceId,
            description,
            encRate,
            encAmount,
            encPrice,
            orderIndex,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceEntryDelete(invoiceEntryId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceEntryDelete(invoiceEntryId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteInvoiceEntry(invoiceEntryId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
