import axiosInstance, { sessionId, mode } from './axios';

//--------Reports----------
export const reportsApi = {
  reportList(branchIds, periodFrom, periodTill) {
    return axiosInstance
      .get(
        `taskReport/list?${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportEntryUpdate(taskReportId, taskReportEntryId, description, tteIds) {
    return axiosInstance
      .get(
        `taskReport/entry/set?taskReportId=${taskReportId}&taskReportEntryId=${taskReportEntryId}&description=${description}&tteIds=${tteIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportUpdateInSecondStep(taskReportEntryId, efficientHours, description) {
    return axiosInstance
      .get(
        `taskReport/entry/update?taskReportEntryId=${taskReportEntryId}&efficientHours=${efficientHours}&description=${description}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportEntryDelete(taskReportEntryId) {
    return axiosInstance
      .get(
        `taskReport/entry/delete?taskReportEntryId=${taskReportEntryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportEntryList(taskReportId) {
    return axiosInstance
      .get(
        `taskReport/entry/list?taskReportId=${taskReportId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportCreate(branchId, projectId, periodFrom, periodTill) {
    return axiosInstance
      .get(
        `taskReport/create?branchId=${branchId}&projectId=${projectId}&periodFrom=${periodFrom}&periodTill=${periodTill}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportDelete(taskReportId) {
    return axiosInstance
      .get(
        `taskReport/delete?taskReportId=${taskReportId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportStatusCompleted(taskReportId) {
    return axiosInstance
      .get(
        `taskReport/status/completed?taskReportId=${taskReportId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportStatusReopen(taskReportId) {
    return axiosInstance
      .get(
        `taskReport/status/reopen?taskReportId=${taskReportId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  taskReportStatusSent(taskReportId) {
    return axiosInstance
      .get(
        `taskReport/status/sent?taskReportId=${taskReportId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
