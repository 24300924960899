import { settingsApi } from '../../api/settingsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  countryList: null,
  currencyList: null,
  bankList: null,
  positionsList: null,
  positionsListNew: null,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startPositionsListLoading(state) {
      state.isPositionsListLoading = true;
    },

    hasError(state, action) {
      state.error = action.payload;
    },
    //country
    setCountryList(state, action) {
      state.countryList = action.payload;
    },
    addInCountryList(state, action) {
      state.countryList = [...state.countryList, action.payload];
    },
    updateCountry(state, action) {
      const newCountry = action.payload;
      state.countryList = state.countryList.map((item) => {
        if (item.id === newCountry.id)
          return { ...item, name: newCountry.name, code: newCountry.code };
        return item;
      });
    },
    deleteCountry(state, action) {
      const id = action.payload;
      state.countryList = state.countryList.filter((item) => item.id !== id);
    },
    //currency
    setCurrencyList(state, action) {
      state.currencyList = action.payload;
    },
    addInCurrencyList(state, action) {
      state.currencyList = [...state.currencyList, action.payload];
    },
    deleteCurrency(state, action) {
      const id = action.payload;
      state.currencyList = state.currencyList.filter((item) => item.id !== id);
    },
    //bank
    setBankList(state, action) {
      state.bankList = action.payload;
    },
    addInBankList(state, action) {
      state.bankList = [...state.bankList, action.payload];
    },
    updateBank(state, action) {
      const newBank = action.payload;
      state.bankList = state.bankList.map((item) => {
        if (item.id === newBank.id)
          return { ...item, name: newBank.name, countryId: newBank.countryId };
        return item;
      });
    },
    deleteBank(state, action) {
      const id = action.payload;
      state.bankList = state.bankList.filter((item) => item.id !== id);
    },
    //positions
    setPositionsList(state, action) {
      state.positionsList = action.payload;
    },
    setPositionsListNew(state, action) {
      state.isPositionsListLoading = false;
      state.positionsListNew = action.payload;
    },
    addInPositionsList(state, action) {
      state.positionsList = [...state.positionsList, action.payload];
    },
    updatePosition(state, action) {
      const newPosition = action.payload;
      state.positionsList = state.positionsList.map((item) => {
        if (item.id === newPosition.id)
          return {
            ...item,
            name: newPosition.name,
            typeOfWork: newPosition.typeOfWork,
          };
        return item;
      });
    },
    deletePosition(state, action) {
      const id = action.payload;
      state.positionsList = state.positionsList.filter(
        (item) => item.id !== id
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { setReport, setReportTotal } = slice.actions;

// ---------------Country---------------------------------

export function getCountryList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setCountryList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countyCreate(name, code) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryCreate(name, code);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.addInCountryList({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countryDelete(countryId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryDelete(countryId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteCountry(countryId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countryUpdate(countryId, name, code) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryUpdate(countryId, name, code);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.updateCountry({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Currency---------------------------------

export function getCurrencyList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setCurrencyList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function currencyCreate(code) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyCreate(code);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.addInCurrencyList({ id, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function currencyDelete(currencyId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyDelete(currencyId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteCurrency(currencyId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Bank---------------------------------

export function getBankList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setBankList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankCreate(name, countryId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankCreate(name, countryId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.addInBankList({ id, name, countryId }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankUpdate(bankId, name, countryId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankUpdate(bankId, name, countryId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.updateBank({ id, name, countryId }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankDelete(bankId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankDelete(bankId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deleteBank(bankId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Positions---------------------------------

export function getPositionList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.positionList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setPositionsList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPositionListNew() {
  return async (dispatch) => {
    dispatch(slice.actions.startPositionsListLoading());
    try {
      const response = await settingsApi.positionListNew();
      if (response.data.status === 200 && response.data.errors === null) {
        let data = response.data.body.items;
        dispatch(slice.actions.setPositionsListNew(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionСreate(name, typeOfWork) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.positionСreate(name, typeOfWork);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.addInPositionsList({ id, name, typeOfWork }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionUpdate(positionId, name, typeOfWork) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.positionUpdate(
        positionId,
        name,
        typeOfWork
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const id = response.data.object.item;
        dispatch(slice.actions.updatePosition({ id, name, typeOfWork }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionDelete(positionId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.positionDelete(positionId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        dispatch(slice.actions.deletePosition(positionId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
