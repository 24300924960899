import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import infoFill from '@iconify-icons/eva/info-fill';
import alertCircleFill from '@iconify-icons/eva/alert-circle-fill';
import alertTriangleFill from '@iconify-icons/eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify-icons/eva/checkmark-circle-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      width: 40,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadiusSm,
      '& svg': { width: 24, height: 24 },
    },
  };
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({
  theme,
}) => {
  const isLight = theme.palette.mode === 'light';

  const createStyle = (color) => {
    return {
      color: `${theme.palette.grey[isLight ? 800 : 0]} !important`,
      backgroundColor: `${theme.palette.grey[isLight ? 0 : 800]} !important`,
      svg: {
        color: theme.palette[color].main,
      },

      padding: theme.spacing(1.5),
      boxShadow: theme.shadows[25].z8,
      borderRadius: theme.shape.borderRadius,
      '#notistack-snackbar': {
        padding: 0,
        span: {
          backgroundColor: alpha(theme.palette[color].main, 0.16),
        },
      },
      leadingTrim: 'both',

      textEdge: 'cap',
      fontFamily: 'Ranade',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightMedium,
    };
  };

  return {
    '&.notistack-MuiContent-success': {
      ...createStyle('success'),
    },
    '&.notistack-MuiContent-error': {
      ...createStyle('error'),
    },
    '&.notistack-MuiContent-info': {
      ...createStyle('info'),
    },
    '&.notistack-MuiContent-warning': {
      ...createStyle('warning'),
    },
  };
});

// ----------------------------------------------------------------------

NotistackProvider.propTypes = {
  children: PropTypes.node,
};

function NotistackProvider({ children }) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: (
          <span className={classes.icon}>
            <Icon icon={checkmarkCircle2Fill} />
          </span>
        ),
        error: (
          <span className={classes.icon}>
            <Icon icon={infoFill} />
          </span>
        ),
        warning: (
          <span className={classes.icon}>
            <Icon icon={alertTriangleFill} />
          </span>
        ),
        info: (
          <span className={classes.icon}>
            <Icon icon={alertCircleFill} />
          </span>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
