import { performancesReportApi } from '../../api/performancesReportApi';
import { reportsApi } from '../../api/reportsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  reportsList: null,
  createMode: false,
  // newReport: null,
  // newEntryId: null,
  taskReportEntryList: [],
  // openedReport: null,
  taskReport: null,
  isTaskReportCreating: false,
  reportDeleteSuccess: null,
  errorEntryUpdate: null,
  // openedReportStatus: 'OPEN',
  currentPeriodsForReport: null,
  performanceReports: null,
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    hasErrorEntryUpdate(state, action) {
      state.errorEntryUpdate = action.payload;
    },
    setReportList(state, action) {
      state.reportsList = action.payload;
    },
    setCreateMode(state, action) {
      state.createMode = action.payload;
    },
    // setNewReport(state, action) {
    //   state.taskReport = action.payload;
    // },
    // setOpenedReport(state, action) {
    //   state.taskReport = action.payload;
    // },
    setTaskReport(state, action) {
      state.taskReport = action.payload;
      state.isTaskReportCreating = false;
    },
    setIsTaskReportCreating(state, action) {
      state.isTaskReportCreating = action.payload;
    },
    addNewTaskReport(state, action) {
      state.reportsList = [...state.reportsList, action.payload];
    },
    // setNewEntryId(state, action) {
    //   state.newEntryId = action.payload;
    // },
    deleteReport(state, action) {
      const reportId = action.payload;
      state.reportsList = state.reportsList.filter(
        (item) => item.id !== reportId
      );
      state.reportDeleteSuccess = true;
    },
    isDeleteReportStatus(state, action) {
      state.reportDeleteSuccess = action.payload;
    },
    setTaskReportEntryList(state, action) {
      state.taskReportEntryList = action.payload;
    },
    setTaskReportEntryUpdate(state, action) {
      const newData = action.payload;
      state.taskReportEntryList = state.taskReportEntryList.map((item) => {
        if (item.id === newData.id)
          return {
            ...item,
            description: newData.description,
            tteIds: newData.tteIds,
            totalHours: newData.totalHours,
          };
        return item;
      });
    },
    addTaskReportEntry(state, action) {
      const newData = action.payload;
      state.taskReportEntryList = [...state.taskReportEntryList, newData];
    },
    deleteTaskReportEntry(state, action) {
      const id = action.payload;
      state.taskReportEntryList = state.taskReportEntryList.filter(
        (item) => item.id !== id
      );
    },
    setTaskReportUpdateInSecondStep(state, action) {
      // state.taskReportEntryList = action.payload;
      const newData = action.payload;
      state.taskReportEntryList = state.taskReportEntryList.map((item) => {
        if (item.id === newData.id)
          return {
            ...item,
            description: newData.description,
            efficientHours: newData.totalHours,
          };
        return item;
      });
    },
    changeStatus(state, action) {
      const reportId = action.payload.taskReportId;
      const status = action.payload.status;
      state.reportsList = state.reportsList.map((item) => {
        if (item.id === reportId) return { ...item, status: status };
        return item;
      });
      if (status === 'OPEN') {
        state.taskReport.status = status;
      }
    },
    // changeStatus(state, action) {
    //   // const reportId = action.payload.id;
    //   // const status = action.payload.status;
    //   // state.reportsList = state.reportsList.map(item => {
    //   //   if (item.id === reportId) return {...item, status: status };
    //   //   return item;
    //   // });
    //   state.openedReportStatus = action.payload;
    // },
    setCurrentPeriodsForReport(state, action) {
      state.currentPeriodsForReport = action.payload;
    },
    setPerformanceReports(state, action) {
      state.performanceReports = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCreateMode,
  changeStatus,
  setReportList,
  setTaskReport,
  setTaskReportEntryList,
  isDeleteReportStatus,
  hasErrorEntryUpdate,
  setCurrentPeriodsForReport,
  setPerformanceReports,
} = slice.actions;

export function getReportList() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await reportsApi.reportList();
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setReportList(data));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTaskReportEntryList(taskReportId) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await reportsApi.taskReportEntryList(taskReportId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        let data = response.data.object.items;
        dispatch(slice.actions.setTaskReportEntryList(data));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportCreate(branchId, projectId, periodFrom, periodTill) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsTaskReportCreating(true));
    try {
      const response = await reportsApi.taskReportCreate(
        branchId,
        projectId,
        periodFrom,
        periodTill
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const data = response.data.object.item;
        dispatch(
          slice.actions.setTaskReport({
            id: data,
            branchId: parseInt(branchId),
            projectId,
            status: 'OPEN',
            periodFrom,
            periodTill,
          })
        );
        dispatch(
          slice.actions.addNewTaskReport({
            id: data,
            branchId: parseInt(branchId),
            projectId: parseInt(projectId),
            status: 'OPEN',
            periodFrom,
            periodTill,
          })
        );
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportEntryUpdate(
  taskReportId,
  taskReportEntryId,
  description,
  tteIds,
  totalHours,
  projectId
) {
  return async (dispatch) => {
    dispatch(slice.actions.hasErrorEntryUpdate(null));
    try {
      const response = await reportsApi.taskReportEntryUpdate(
        taskReportId,
        taskReportEntryId,
        description,
        tteIds
      );
      if (
        response &&
        response.data.status === 200 &&
        response.data.errors.length === 0
      ) {
        const data = response.data.object.item;
        const tasksIds = tteIds.split(',');

        if (taskReportEntryId)
          dispatch(
            slice.actions.setTaskReportEntryUpdate({
              id: data,
              description,
              tteIds: tasksIds,
              totalHours,
            })
          );
        else
          dispatch(
            slice.actions.addTaskReportEntry({
              id: data,
              description,
              tteIds: tasksIds,
              totalHours,
              efficientHours: {},
              projectId,
            })
          );

        return data;
      } else throw new Error('Server not responding');
    } catch (error) {
      dispatch(slice.actions.hasErrorEntryUpdate(error.message));
    }
  };
}

export function taskReportUpdateInSecondStep(
  taskReportEntryId,
  efficientHours,
  description,
  forUpdateObject
) {
  return async (dispatch) => {
    // dispatch(slice.actions.setNewEntryId(null));
    try {
      const response = await reportsApi.taskReportUpdateInSecondStep(
        taskReportEntryId,
        efficientHours,
        description
      );
      if (response.data.status === 200 && response.data.errors.length === 0) {
        const data = response.data.object.item;
        dispatch(
          slice.actions.setTaskReportUpdateInSecondStep({
            id: data,
            description,
            totalHours: forUpdateObject,
          })
        );
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportEntryDelete(taskReportEntryId) {
  return async (dispatch) => {
    // dispatch(slice.actions.setNewEntryId(null));
    try {
      const response =
        await reportsApi.taskReportEntryDelete(taskReportEntryId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        // const data = response.data.object.item;
        dispatch(slice.actions.deleteTaskReportEntry(taskReportEntryId));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportDelete(taskReportId) {
  return async (dispatch) => {
    dispatch(slice.actions.isDeleteReportStatus(null));
    try {
      const response = await reportsApi.taskReportDelete(taskReportId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        // const data = response.data.object.item;
        dispatch(slice.actions.deleteReport(taskReportId));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.isDeleteReportStatus(error.message));
    }
  };
}

export function taskReportStatusCompleted(taskReportId) {
  return async (dispatch) => {
    try {
      const response = await reportsApi.taskReportStatusCompleted(taskReportId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        // const id = response.data.object.item;
        dispatch(
          slice.actions.changeStatus({ taskReportId, status: 'COMPLETED' })
        );
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportStatusReopen(taskReportId) {
  return async (dispatch) => {
    try {
      const response = await reportsApi.taskReportStatusReopen(taskReportId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        // const id = response.data.object.item;
        dispatch(slice.actions.changeStatus({ taskReportId, status: 'OPEN' }));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taskReportStatusSent(taskReportId) {
  return async (dispatch) => {
    try {
      const response = await reportsApi.taskReportStatusSent(taskReportId);
      if (response.data.status === 200 && response.data.errors.length === 0) {
        // const id = response.data.object.item;
        dispatch(slice.actions.changeStatus('SENT'));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentPerformancePeriod() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await performancesReportApi.getCurrentPeriod();
      let reports = response.data.object;
      dispatch(slice.actions.setCurrentPeriodsForReport(reports));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMemberPerformanceReportList(periodFrom, periodTill) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await performancesReportApi.memberReportList(
        periodFrom,
        periodTill
      );
      let reports = response.data.object.items;
      dispatch(slice.actions.setPerformanceReports(reports));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
