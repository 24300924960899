import axiosInstance, { sessionId, mode } from './axios';

// --------Member Report OverTime----------
export const memberReportApi = {
  memberReportList(periodFrom, periodTill) {
    return axiosInstance
      .get(
        `memberReport/list?periodFrom=${periodFrom}&periodTill=${periodTill}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  overtimeConfirm(memberId, year, month, overtime) {
    return axiosInstance
      .get(
        `memberReport/overtime/confirm?memberId=${memberId}&year=${year}&month=${month}&overtime=${overtime}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  overtimeReject(memberId, year, month) {
    return axiosInstance
      .get(
        `memberReport/overtime/reject?memberId=${memberId}&year=${year}&month=${month}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
