import { map, reject } from 'lodash';
import { usersApi } from '../../api/usersApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isUpdating: false,
  error: false,
  events: [],
  eventAdditionalInfo: {
    links: {},
    offset: 0,
    per_page: 0,
    total: 0,
  },
  isOpenModal: false,
  selectedEventId: null,
  // selectedRange: null,
  selectedDay: null,
  openDayTasks: null,
  hints: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS ADDITIONAL INFO
    getEventsAdditionalInfo(state, action) {
      state.eventAdditionalInfo = action.payload;
    },

    // GET EVENTS
    getEventsListSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.events = [...state.events, newEvent];
      state.isUpdating = false;
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isUpdating = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = reject(state.events, { id: eventId });

      state.isUpdating = false;
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      // state.selectedEventId = null;
      // state.selectedRange = null;
    },

    // SELECT Date
    setSelectDay(state, action) {
      state.isOpenModal = true;
      state.selectedDay = action.payload;
    },

    setOpenDayTasks(state, action) {
      state.openDayTasks = action.payload;
    },

    // HINTS
    setHints(state, action) {
      state.isLoading = false;
      state.hints = action.payload;
    },
    startUpdating(state) {
      state.isUpdating = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, hasError, selectEvent, setOpenDayTasks } =
  slice.actions;

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}

// ----------------------------------------------------------------------

export function getEventsList(id, fromDate, tillDate) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.allTasksList(id, fromDate, tillDate);
      dispatch(slice.actions.getEventsListSuccess(response.data.object.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventsListNew(id, fromDate, tillDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.allTasksListNew(id, fromDate, tillDate);
      const { items, links, offset, per_page, total } = response.data.body;
      dispatch(slice.actions.getEventsListSuccess(items));
      dispatch(
        slice.actions.getEventsAdditionalInfo({
          links,
          offset,
          per_page,
          total,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearEventsList() {
  return (dispatch) => {
    dispatch(slice.actions.getEventsListSuccess([]));
  };
}

export function selectDay(date) {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectDay(date));
  };
}

export function getHint(id, fromDate, tillDate) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.hint(id, fromDate, tillDate);
      dispatch(slice.actions.setHints(response.data.object.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHintNew(id, fromDate, tillDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.hintNew(id, fromDate, tillDate);
      dispatch(slice.actions.setHints(response.data.body));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTask(
  memberId,
  projectId,
  value,
  date,
  description,
  extendedDescription,
  status,
  movedFromTaskId
) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      const response = await usersApi.createTask(
        memberId,
        projectId,
        value,
        date,
        description,
        extendedDescription,
        status,
        movedFromTaskId
      );

      if (response.data.status === 200) {
        const newTask = {
          date: date,
          description: description,
          id: response.data.object.item,
          projectId: projectId,
          value: value,
          extendedDescription: extendedDescription,
          status: status,
        };
        dispatch(slice.actions.createEventSuccess(newTask));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTaskNew(day, description, memberId, minutes, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      const response = await usersApi.createTaskNew(
        day,
        description,
        memberId,
        minutes,
        projectId
      );

      // Backend always returns status 200 but in data we can get error
      if (response.data.status === 201) {
        const newTask = {
          day,
          description,
          id: response.data.body.id,
          memberId,
          minutes,
          projectId,
        };
        dispatch(slice.actions.createEventSuccess(newTask));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTask(
  tteId,
  projectId,
  value,
  date,
  description,
  extendedDescription,
  status
) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      const response = await usersApi.updateTask(
        tteId,
        projectId,
        value,
        date,
        description,
        extendedDescription,
        status
      );

      if (response.data.status === 200) {
        const updatedTask = {
          date: date,
          description: description,
          id: response.data.object.item,
          projectId: projectId,
          value: value,
          extendedDescription: extendedDescription,
          status: status,
        };
        dispatch(slice.actions.updateEventSuccess(updatedTask));
      } else throw new Error();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTaskNew(
  tteId,
  day,
  description,
  memberId,
  minutes,
  projectId
) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      const response = await usersApi.updateTaskNew(
        tteId,
        day,
        description,
        memberId,
        minutes,
        projectId
      );

      // Backend always returns status 200 but in data we can get error
      if (response.data.status === 200) {
        const updatedTask = {
          day,
          description,
          id: response.data.body.id,
          memberId,
          minutes,
          projectId,
        };
        dispatch(slice.actions.updateEventSuccess(updatedTask));
      } else throw new Error(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTask(tteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      const response = await usersApi.deleteTask(tteId);
      dispatch(slice.actions.deleteEventSuccess(tteId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTaskNew(tteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdating());
    try {
      await usersApi.deleteTaskNew(tteId);
      dispatch(slice.actions.deleteEventSuccess(tteId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOpenDayTasks(id, date) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.openTasksDay(id, date);
      if (
        response.data.status === 200 &&
        response.data.object &&
        response.data.object.items.length > 0
      ) {
        dispatch(slice.actions.setOpenDayTasks(response.data.object.items));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
