import React, { useEffect, memo } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToSystemNew, getMeInformNew } from '../../redux/slices/user';
import { Box, CircularProgress } from '@material-ui/core';

function AuthProtect({ children, ...rest }) {
  const dispatch = useDispatch();
  const { me, isMeLoading, isAccessSystemLoading, userAccesToTabsNew } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (!me) {
      dispatch(getMeInformNew());
    }
  }, [dispatch]);

  useEffect(() => {
    if (me) {
      dispatch(getAccessToSystemNew());
    }
  }, [dispatch, me]);

  if (isMeLoading || isAccessSystemLoading) {
    return (
      <Box
        sx={{
          zIndex: 99,
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={32} thickness={2.4} />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        me ? (
          children
        ) : (
          <>
            {(location.pathname !== '/sign-in' ||
              location.pathname !== '/ui/sign-in') && (
              <Redirect
                to={{
                  pathname: '/sign-in',
                  state: { from: location },
                }}
              />
            )}
          </>
        )
      }
    />
  );
}

export default memo(AuthProtect);
