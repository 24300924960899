import axiosInstance, { axiosNewInstance, mode, sessionId } from './axios';

// --------Settings----------
export const settingsApi = {
  //----Country----
  countryList() {
    return axiosInstance
      .post(
        `country/list${mode === 'development' ? '?sessionId=' + sessionId : ''}`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryGet(countryId) {
    return axiosInstance
      .get(
        `country/get?countryIds=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryCreate(name, code) {
    return axiosInstance
      .post(
        `country/create?name=${name}&code=${code}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryUpdate(countryId, name, code) {
    return axiosInstance
      .post(
        `country/update?name=${name}&code=${code}&countryId=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryDelete(countryId) {
    return axiosInstance
      .get(
        `country/delete?countryId=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Currency----
  currencyList() {
    return axiosInstance
      .post(
        `currency/list${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyGet(currencyIds) {
    return axiosInstance
      .get(
        `currency/get?currencyIds=${currencyIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyCreate(code) {
    return axiosInstance
      .post(
        `currency/create?code=${code}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyDelete(currencyId) {
    return axiosInstance
      .get(
        `currency/delete?currencyId=${currencyId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Bank----
  bankList() {
    return axiosInstance
      .post(
        `bank/list${mode === 'development' ? '?sessionId=' + sessionId : ''}`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankCreate(name, countryId) {
    return axiosInstance
      .post(
        `bank/create?name=${name}&countryId=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankUpdate(bankId, name, countryId) {
    return axiosInstance
      .post(
        `bank/update?name=${name}&bankId=${bankId}&countryId=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankDelete(bankId) {
    return axiosInstance
      .post(
        `bank/delete?bankId=${bankId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Positions----
  positionList() {
    return axiosInstance
      .post(
        `position/list${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionListNew() {
    return axiosNewInstance.get('position?fields=*').then((response) => {
      return response;
    });
  },
  positionСreate(name, typeOfWork) {
    return axiosInstance
      .post(
        `position/create?name=${name}&typeOfWork=${typeOfWork}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionUpdate(positionId, name, typeOfWork) {
    return axiosInstance
      .post(
        `position/update?name=${name}&positionId=${positionId}&typeOfWork=${typeOfWork}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionDelete(positionId) {
    return axiosInstance
      .post(
        `position/delete?positionId=${positionId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
